<template>
  <div id="bg" class="bg-antrian">   
    <b-overlay z-index="9999"
      :show="loadingOverlay"
      rounded="sm"
      id="main"
      class="flex flex-column justify-content-start"
    > 
      <!-- yang baru masuk -->
      <div class="active flex" style="height:15%; margin-top: 0; margin-left: 0;">
        <div class="process flex" style="width: 100%; margin-right: 0;">
          <div class="header bg-white flex"
            style="height:100%; padding-left: 30px; padding-right: 30px; border-radius:0 !important;background-image: url('/img/display-header-bg.png');background-size: cover;background-position: center;">
            <div class="loket-split flex" style="justify-content: left; width:30%; flex-direction: initial;">
              <div class="wrap_logo">
                <img :src="assetLocal('img/logo-rsh-wide.png')" alt="" />
              </div>
            </div>
            <div class="loket-split flex" style="width:60%;">
              <div class="date_time_wrap">
                <h3 class="text-primary" style="font-size:3vh; text-align:center;">LAYANAN ANTRIAN RS HARAPAN MAGELANG</h3>
                <p class="text-primary" style="margin:0;   text-align:center; font-size:1.8vh; padding-top:10px;">Jl. Panembahan Senopati
                  No. 11, Jurangombo Utara,<br />Kec. Magelang Sel., Kota Magelang, Jawa Tengah 56123</p>
              </div>
            </div>
            <div class="loket-split flex" style="width:30%;">
              <div class="date_time_wrap">
                <p class="text-primary">{{ dates }}</p>
                <h3 class="text-primary">{{ times }}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- loket bawah / yang lagi aktif -->
      <div class="content_post flex mt-1">
        <div class="w-100" style="display:block;background-color: #F5F5F5;">
          <div class="wrap_preview_checkin">
            <div class="success_ci">
              <i class="icon-checkmark"></i>
            </div>
            <div class="content_checkin">
              <h3>Data Reservasi Pasien      
              </h3>
              <p>{{dateTime || getTime()}}</p>
            </div>
            <div class="table-responsive text-left mt-3 mb-3">
              <table class="table table-bordered mb-0 table-xs">
                <tbody>
                  <tr>
                    <td>
                      <div class="wrap_preview_data">
                        <span>Nomor Registrasi</span>
                        <p class="mb-0 font-weight-bold">{{row.ar_reg_code||"-"}}</p>
                      </div>
                    </td>
                    <td>
                      <div class="wrap_preview_data">
                        <span>Nomor RM</span>
                        <p class="mb-0 font-weight-bold">{{row.ap_code||"-"}}</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="wrap_preview_data">
                        <span>Nama Pasien</span>
                        <p class="mb-0 font-weight-bold">{{row.ap_fullname||"-"}}</p>
                      </div>
                    </td>
                    <td>
                      <div class="wrap_preview_data">
                        <span>Jenis Kelamin</span>
                        <p class="mb-0 font-weight-bold">{{row.cg_label||"-"}}</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                   <td>
                      <div class="wrap_preview_data">
                        <span>Cara Bayar</span>
                        <p class="mb-0 font-weight-bold" v-if="row.jaminan == 1">Umum/Pribadi</p>
                        <p class="mb-0 font-weight-bold" v-else-if="row.jaminan == 2">Asuransi</p>
                        <p class="mb-0 font-weight-bold" v-else-if="row.jaminan == 3">BPJS Kesehatan</p>
                        <p class="mb-0 font-weight-bold" v-else> - </p>
                      </div>
                    </td>
                    <td>
                      <div class="wrap_preview_data">
                        <span>Pemeriksaan</span>
                        <p class="mb-0 font-weight-bold">{{row.mpo_name||"-"}}</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="wrap_preview_data">
                        <span>Tanggal, Jam Periksa</span>
                        <p class="mb-0 font-weight-bold">{{row.ar_reg_date | moment("DD MMMM YYYY, HH:mm")}} WIB</p>
                      </div>
                    </td>
                    <td>
                      <div class="wrap_preview_data">
                        <span>Diperiksa Oleh</span>
                        <p class="mb-0 font-weight-bold">{{row.bu_full_name||"-"}}</p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="info_checkin" style="cursor: pointer;text-align: center;">
              <p>Silakan klik tombol Frista atau Finger Print & verifikasi data pada alat yang tersedia.           
              </p>
            </div>

            <div class="mt-1 text-center" v-if="row.ar_is_finger == 'Y' && row.jaminan == 3 && row.ap_usia >= 17">
              <a href="javascript:;" @click="openQR" class="btn btn-primary btn-display text-uppercase font-weight-semibold mr-2">Finger Print BPJS</a>
              
              <a href="javascript:;" @click="openFrista" class="btn btn-info btn-display text-uppercase font-weight-semibold">Frista BPJS</a>
            </div>

            
            <div class="mt-1 text-center" v-if="row.ar_is_finger == 'Y' && row.ar_is_checkin == 'Y'">
               <a href="javascript:;" @click="printPage" :class="iscanPrint ? 'btn btn-warning btn-display text-uppercase font-weight-semibold' : 'btn btn-secondary btn-display text-uppercase font-weight-semibold'"><i class="icon-printer mr-3" style="font-size: 30px;"></i> Check-In </a>
            </div>
            
            <!--
            <div class="mt-1 text-center" v-if="row.ar_is_finger == 'Y' && row.ar_is_checkin == 'Y' && showKembali">
              <a href="javascript:;" @click="clickBack" class="btn btn-primary btn-display text-uppercase font-weight-semibold">Kembali</a>
            </div>
            -->

          </div>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
  import GlobalVue from '@/libs/Global.vue'
  import Gen from '@/libs/Gen.js'
  import $ from 'jquery'
  import _ from 'lodash'
  const moment = require('moment')

  export default {
    extends: GlobalVue,
    data() {
      return {
        row: {},
        dateTime: null,
        times: null,
        dates: null,
        showKembali : false,
        iscanPrint: true
      }
    },
    methods: {
        baseUrlFinger(){
          return process.env.VUE_APP_FINGER_PRINT_DISPLAY
        },
        openQR(){
          this.iscanPrint = true
          let nik = this.row.ap_nik
          let link = nik ? ('http://localhost:5000/run-finger-print?nik='+nik) : 'http://localhost:5000/run-finger-print?nik=' 

          fetch(link) // Use the correct server address.
          .then(response => {
            if (!response.ok) {
              throw new Error('Failed to run Python script')
            }
            return response.text()
          })
          .then(result => {
            console.log(result)
          })
          .catch(error => {
            console.error(error.message)
          })
       
          // this.loadingOverlay = true
          // let url = ''
          // if(this.row.jaminan == 3){
          //   url = this.baseUrlFinger()+'?type=bpjs&noRM='+this.row.ap_code+'&nik='+this.row.ap_nik
          // }else{
          //   url = this.baseUrlFinger()+'?noRM='+this.row.ap_code
          // }
          // setTimeout(()=>{
          //   this.loadingOverlay = false
          //   window.location.href = url 
          // },1000)
        },
        openFrista(){
          // ini nnti frista
          this.iscanPrint = true
          let nik = this.row.ap_nik
          let link = nik ? ('http://localhost:5000/run-frista?nik='+nik) : 'http://localhost:5000/run-frista?nik=' 

          fetch(link) // Use the correct server address.
          .then(response => {
            if (!response.ok) {
              throw new Error('Failed to run Python script')
            }
            return response.text()
          })
          .then(result => {
            console.log(result)
          })
          .catch(error => {
            console.error(error.message)
          })
        },
        apiGet(){
            this.loadingOverlay = true
            // default antrian perawat
            Gen.apiRestDisplay(
                "/displayDoneCheckIn?regId="+(this.$route.params.pageSlug)
            ).then(res=>{
              this.row = res.data.row
              this.$socket.emit('refresh_data', {to : this.uPerawat})
              this.loadingOverlay = false
              if(this.row.jaminan == 3){
                if(this.row.ap_usia >= 17){
                  this.iscanPrint = false
                }else{
                  this.iscanPrint = true
                }
              }else{
                this.iscanPrint = true
              }
            })
        },
        getTime(){
            this.dateTime = moment().format('dddd, Do MMMM YYYY, HH:mm:ss')
        },
        getTimeF(){
            this.times = moment().format('HH:mm:ss')
        },
        getDatesF(){
            this.dates = moment().format('dddd, Do MMMM YYYY')
        }, 
        
        getConfigDynamic(master, value, val = 'text') {
            let text = ''
            if (value) {
                let index = (master||[]).findIndex(x => x.value == value)
                if (index !== -1) {
                text = master[index][val]
                }
            }
            return text
        },
        clickBack(){
          this.$router.push({name:'DisplayAntrian'}).catch(()=>{})
        },
        printPage(){
          if(!this.iscanPrint){
            return 
          }
          this.loadingOverlay = true
          
          Gen.apiRestDisplay(
              "/updateTask?regId="+this.row.ar_id
          ).then(res=>{ 
            this.loadingOverlay = false
            this.showKembali = true
            
            setTimeout(()=>{
              this.$router.push({name:'DisplayAntrian'}).catch(()=>{})
            },500)

            let newWindow = window.open('', '', 'left=0,top=0,width=302,height=350,toolbar=0,scrollbars=0,status=0,addressbar=0'),
            document = newWindow.document.open(),
            pageContent =
                  '<!DOCTYPE html>' +
                  '<html>' +
                  '<head>' +
                  '<meta charset="utf-8" />' +
                  '<title>Inventory</title>' +
                  '<style type="text/css">@page { size: auto;  margin: 0mm; }body {-webkit-print-color-adjust: exact; font-family: Arial; }</style>' +
                  '</head>' +
                  '<body>' +
                  `<div style="position: relative;max-width: 400px;">
                      <div
                        style="height:100%;position: relative;font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;color: #333;line-height: 1.5;font-size: 13px;">
                        <div>
                          <table border="0" style="border-collapse: collapse;width: 100%;">
                            <tr>
                              <td style="padding:0;">
                              <table style="border-collapse: collapse;width: 100%;">
                                  <tr>
                                      <td style="padding:8px 12px;">
                                          <div
                                          style="float: left;width: 15%;vertical-align: middle;box-sizing: border-box;padding-right: 8px;">
                                          <img src="${this.assetLocal('global_assets/images/logo-rsh-mark.png')}" style="width: 100%;" alt="">
                                          </div>
                                          <div
                                          style="text-transform: uppercase;float: left;width: 75%;vertical-align: middle;box-sizing: border-box;padding-left: 8px;text-align: center;">
                                          <h1 style="margin:0;font-size: 14px;line-height: 20px;margin-bottom: 2px;">Rumah Sakit Harapan
                                              Magelang</h1>
                                          </div>
                                          <div style="display: table-cell; clear: both;"></div>
                                      </td>
                                  </tr>
                                  <tr>
                                      <td style="padding:2px 12px;">
                                          <p style="margin:0;font-size: 12px;text-align:center;">Jl. P. Senopati No. 11 Magelang 56123<br />Telp. (0293) 364033
                                              s/d 364035 <br /> Fax. (0293) 364037</p>
                                          <div style="display: table-cell; clear: both;"></div>
                                        </td>
                                  </tr>
                              </table>
                              </td>
                            </tr>
                            <tr>
                              <td style="padding:20px;">
                                <table style="border-collapse: collapse;width: 100%;">
                                  <tbody>
                                    <tr>
                                      <td colspan="2">
                                        <p style="margin:  0; text-align:center; font-size:16px;">BUKTI RESERVASI ANTRIAN: </p>
                                        <h3 style="font-weight: bold; text-transform: uppercase;letter-spacing: 6px;text-align: center;font-size: 60px;
                                          margin: 0px 0 10px 0; line-height:1;">
                                          ${this.row.ar_no_antrian||'-'}</h3>
                                        <p style="margin: 8px 0; text-align:center; font-size:14px;">Nomor antrian ini berlaku sebagai
                                          nomor antrian perawat, antrian periksa dokter, dan antrian pengambilan obat.
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <p style="margin:  10px 0 00; color:#000; font-size:12px;">JAMINAN </p>
                                      </td>
                                      <td>
                                        <p style="margin:  10px 0 0 ;  color:#000; font-size:12px;"> : 
                                        ${this.row.mcp_name||'-'} </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <p style="margin:  0px 0 00; color:#000; font-size:12px;">TANGGAL/JAM </p>
                                      </td>
                                      <td>
                                        <p style="margin: 0; color:#000; font-size:12px;"> : ${moment().format('DD MMM YYYY, HH:mm')}</p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <p style="margin:  0 0 10px0; color:#000; font-size:12px;">NOMOR RM </p>
                                      </td>
                                      <td>
                                        <p style="margin:  0 0 10px0; color:#000; font-size:12px;"> : ${this.row.ap_code||"-"}</p>
                                      </td>
                                    </tr>
                                    <tr style="border-top:2px solid #333;">
                                      <td>
                                        <p style="margin:  10px 0 00; color:#000; font-size:12px;">NAMA </p>
                                      </td>
                                      <td>
                                        <p style="margin:  10px 0 00; color:#000; font-size:12px;"> : ${this.row.ap_fullname||"-"}</p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <p style="margin: 0; color:#000; font-size:12px;">ALAMAT </p>
                                      </td>
                                      <td>
                                        <p style="margin: 0; color:#000; font-size:12px;"> : ${this.row.ap_address||"-"}</p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <p style="margin:  0 0 10px ;  color:#000; font-size:12px;">KOTA </p>
                                      </td>
                                      <td>
                                        <p style="margin:  0 0 10px ;  color:#000; font-size:12px;"> : ${this.row.ap_kota||"-"}</p>
                                      </td>
                                    </tr>
                                    <tr style="border-top:2px solid #333;">
                                      <td>
                                        <p style="margin:  10px 0 00; color:#000; font-size:12px;">TANGGAL LAHIR </p>
                                      </td>
                                      <td>
                                        <p style="margin:  10px 0 00; color:#000; font-size:12px;">: ${
                                          moment(this.row.ap_dob).format('DD MMM YYYY')} </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <p style="margin: 0; color:#000; font-size:12px;">LAYANAN  </p>
                                      </td>
                                      <td>
                                        <p style="margin: 0; color:#000; font-size:12px;">: ${this.row.mpo_name}</p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <p style="margin:  0px 0 10px ;  color:#000; font-size:12px;">DOKTER </p>
                                      </td>
                                      <td>
                                        <p style="margin:  0px 0 10px ;  color:#000; font-size:12px;"> : ${this.row.bu_full_name||"-"} </p>
                                      </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        <p style="margin:  0px 0 10px 0;  color: #000; font-size:12px;">LOKET PERAWAT </p>
                                        </td>
                                        <td>
                                        <p style="margin:  0px 0 10px 0;  color: #000; font-size:12px;"> : <b>${this.row.loket_perawat||"-"}</b> </p>
                                        </td>
                                    </tr>
                                    <tr style="${this.row.berlaku_rujukan?'': 'display:none;'}">
                                        <td>
                                        <p style="margin:  0px 0 10px 0;  color: #000; font-size:12px;">Tanggal Berlaku Rujukan </p>
                                        </td>
                                        <td>
                                        <p style="margin:  0px 0 10px 0;  color: #000; font-size:12px;"> : <b>${this.row.berlaku_rujukan} </b></p>
                                        </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                  </div>` +
                  '</body></html>'
              document.write(pageContent)
              document.close()
              newWindow.moveTo(0, 0)
              newWindow.resizeTo(screen.width, screen.height)
              setTimeout(function() {
                  newWindow.print()
                  newWindow.close()
              }, 250)
          }).catch(err => {
            this.loadingOverlay = false
            this.showKembali = true
            if (err) {
              err.statusType = err.status
              err.status = "error"
              err.title = err.response?.data?.title
              err.message = err.response?.data?.message
              err.messageError = err.message
            }
            this.doSetAlertForm(err)
          })

          
        }
        
    },
    computed: {
      getDate() {
        return moment().format('dddd, Do MMMM YYYY, HH:mm:ss')
      }
    },
    mounted() {
      this.apiGet()
      setInterval(() => {
        this.getTime()
      }, 1000)

      setInterval(() => {
        this.getTimeF()
      }, 1000)

      setInterval(() => {
        this.getDatesF()
      }, 1000)
    },
  }
</script>

<style lang="scss" scoped>
  /* arabic */
  @font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnXu61F3f.woff2) format('woff2');
    unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0898-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC;
  }

  /* cyrillic-ext */
  @font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnXO61F3f.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }

  /* cyrillic */
  @font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnXq61F3f.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }

  /* hebrew */
  @font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnXy61F3f.woff2) format('woff2');
    unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
  }

  /* latin-ext */
  @font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnXC61F3f.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }

  /* latin */
  @font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnX661A.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  /* arabic */
  @font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnXu61F3f.woff2) format('woff2');
    unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0898-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC;
  }

  /* cyrillic-ext */
  @font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnXO61F3f.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }

  /* cyrillic */
  @font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnXq61F3f.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }

  /* hebrew */
  @font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnXy61F3f.woff2) format('woff2');
    unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
  }

  /* latin-ext */
  @font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnXC61F3f.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }

  /* latin */
  @font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnX661A.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  /* arabic */
  @font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnXu61F3f.woff2) format('woff2');
    unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0898-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC;
  }

  /* cyrillic-ext */
  @font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnXO61F3f.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }

  /* cyrillic */
  @font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnXq61F3f.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }

  /* hebrew */
  @font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnXy61F3f.woff2) format('woff2');
    unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
  }

  /* latin-ext */
  @font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnXC61F3f.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }

  /* latin */
  @font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnX661A.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  /* arabic */
  @font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnXu61F3f.woff2) format('woff2');
    unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0898-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC;
  }

  /* cyrillic-ext */
  @font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnXO61F3f.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }

  /* cyrillic */
  @font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnXq61F3f.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }

  /* hebrew */
  @font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnXy61F3f.woff2) format('woff2');
    unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
  }

  /* latin-ext */
  @font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnXC61F3f.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }

  /* latin */
  @font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnX661A.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  /* arabic */
  @font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnXu61F3f.woff2) format('woff2');
    unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0898-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC;
  }

  /* cyrillic-ext */
  @font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnXO61F3f.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }

  /* cyrillic */
  @font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnXq61F3f.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }

  /* hebrew */
  @font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnXy61F3f.woff2) format('woff2');
    unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
  }

  /* latin-ext */
  @font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnXC61F3f.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }

  /* latin */
  @font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v28/iJWEBXyIfDnIV7nEnX661A.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  /* arabic */
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nErXyi0A.woff2) format('woff2');
    unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0898-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC;
  }

  /* cyrillic-ext */
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nMrXyi0A.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }

  /* cyrillic */
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nFrXyi0A.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }

  /* hebrew */
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nDrXyi0A.woff2) format('woff2');
    unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
  }

  /* latin-ext */
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nPrXyi0A.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }

  /* latin */
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nBrXw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  /* arabic */
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nErXyi0A.woff2) format('woff2');
    unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0898-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC;
  }

  /* cyrillic-ext */
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nMrXyi0A.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }

  /* cyrillic */
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nFrXyi0A.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }

  /* hebrew */
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nDrXyi0A.woff2) format('woff2');
    unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
  }

  /* latin-ext */
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nPrXyi0A.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }

  /* latin */
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nBrXw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  /* arabic */
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nErXyi0A.woff2) format('woff2');
    unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0898-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC;
  }

  /* cyrillic-ext */
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nMrXyi0A.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }

  /* cyrillic */
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nFrXyi0A.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }

  /* hebrew */
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nDrXyi0A.woff2) format('woff2');
    unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
  }

  /* latin-ext */
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nPrXyi0A.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }

  /* latin */
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nBrXw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  /* arabic */
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nErXyi0A.woff2) format('woff2');
    unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0898-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC;
  }

  /* cyrillic-ext */
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nMrXyi0A.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }

  /* cyrillic */
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nFrXyi0A.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }

  /* hebrew */
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nDrXyi0A.woff2) format('woff2');
    unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
  }

  /* latin-ext */
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nPrXyi0A.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }

  /* latin */
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nBrXw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  /* arabic */
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nErXyi0A.woff2) format('woff2');
    unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0898-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC;
  }

  /* cyrillic-ext */
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nMrXyi0A.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }

  /* cyrillic */
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nFrXyi0A.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }

  /* hebrew */
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nDrXyi0A.woff2) format('woff2');
    unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
  }

  /* latin-ext */
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nPrXyi0A.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }

  /* latin */
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nBrXw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
</style>